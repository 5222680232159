



























import { debounce } from "@/helpers/debounce";
import { useFindAllWarehouseLocation, useMapLocationToOptions } from "@/hooks";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseLocation } from "@/models/interface/logistic.interface";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Option } from "@/models/class/option.class";

const LocationSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    cDefaultValue: {
      type: String,
    },
  },
});

/**
 * @deprecated
 * new component {@linkcode SelectRack}
 */
@Component
export default class LocationSelect extends LocationSelectProps {
  locationOption = {
    data: [] as Option<DataWarehouseLocation>[],
    search: "",
    fetching: true,
    initialData: [] as Option<DataWarehouseLocation>[],
  };

  async created() {
    await this.getLocationList(true);
    if (
      this.cDefaultValue &&
      !this.locationOption.data.find(c => c.value === this.cDefaultValue)
    ) {
      this.fetchMoreLocation();
    }
  }

  @Watch("cDefaultValue")
  onChangeDefaultValue(newValue: string): void {
    if (newValue && !this.locationOption.data.find(c => c.value === newValue)) {
      this.fetchMoreLocation();
    }
  }

  async getLocationList(firstLoad = false) {
    try {
      this.locationOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: ``,
      };
      if (this.locationOption.search) {
        param.search = `warehouse.branchWarehouse.name~*${this.locationOption.search}*_OR_warehouse.branchWarehouse.code~*${this.locationOption.search}*_OR_warehouse.branchWarehouse.address~*${this.locationOption.search}*_OR_warehouse.name~*${this.locationOption.search}*_OR_name~*${this.locationOption.search}*`;
      }
      const res = await useFindAllWarehouseLocation(param);
      this.locationOption.data = useMapLocationToOptions(res);
      if (firstLoad) {
        this.locationOption.initialData = useMapLocationToOptions(res);
      }
      this.locationOption.fetching = false;
    } catch (error) {
      this.locationOption.fetching = false;
    }
  }

  searchLocation(value: string): void {
    debounce(() => {
      this.locationOption.search = value;
      this.getLocationList();
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  resetOptionList(): void {
    this.locationOption.data = this.locationOption.initialData;
  }

  /**
   * Fetch warehpuse list from API until matching with @this value
   */
  async fetchMoreLocation() {
    try {
      this.locationOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
      };
      while (
        !this.locationOption.data.find(c => c.value === this.value) &&
        param.page <= totalPage
      ) {
        const locationOptions = await useFindAllWarehouseLocation(param);
        totalPage = Math.ceil(locationOptions.totalElements / 20);
        this.locationOption.data = this.locationOption.data.concat(
          useMapLocationToOptions(locationOptions)
        );
        param.page++;
      }
      this.locationOption.fetching = false;
    } catch (error) {
      this.locationOption.fetching = false;
    }
  }
}
