var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        showSearch: true,
        filterOption: false,
        allowClear: true,
        loading: _vm.locationOption.fetching,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder
      },
      on: {
        search: _vm.searchLocation,
        input: _vm.handleChange,
        change: _vm.handleChange
      }
    },
    _vm._l(_vm.locationOption.data, function(item) {
      return _c(
        "a-select-option",
        { key: item.key, attrs: { value: item.value } },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.label) + " ")
              ]),
              _vm._v(" " + _vm._s(item.label) + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }